@use './config/' as *;
@forward "./main-scss/";
@forward "./form/common";
body {
    --shape-head: 40rem;
}
.waf-campaign {
    .waf-head {
        @extend %pb-5;
        .title {
            @extend %pb-5;
            @extend %neutral-50;
        }
    }
    .waf-body {
        isolation: isolate;
        @extend %py-5;
        @extend %relative;
        &::before {
            content: "";
            z-index: var(--z-patterns);
            position: absolute;
            top: 0;
            width: var(--window-inner-width);
            left: calc(var(--container-white-space) * -1);
            @include background(var(--neutral-50), "patterns/mobile/campaign-bg.jpg", center/cover fixed);
            @extend %h-100;
            @extend %neutral-50-bg;
        }
    }
    .disclamer {
        margin-bottom: 0;
    }
}
@include mq(col-md) {
    .waf-campaign {
        .waf-body::before {
            @include background(var(--neutral-50), "patterns/campaign-bg.jpg", center/cover fixed);
        }
    }
}